import $api from "../http";
import tg_store from '../store/tg_store';

export default class ContentService {
    static async getCategories() {
        const res = await $api.get('/category')
        return res.data;
    }

    static async getCategoryItem(id: any) {
        const res = await $api.post(`/category/${id}`, {
            count_per_page: 150
        });
        return res.data;
    }

    static async getProduct(id: any) {
        const res = await $api.get(`/product/${id}/`);
        return res.data;
    }
}

