import { observer } from "mobx-react-lite"
import { FC, useState } from "react";

const HomeFooter:FC<any> = () => {
    return (
        <div className="footer">
            <div className="footer__bottom">
                <div className="footer__phone">
                    <a href="tel:88005554296" target="_blank" className="footer__phone-item">8 800 555-42-96</a>
                    <div className="footer__phone-sub">Горячая линия</div>
                    
                    <a href="tel:834320-20-696" target="_blank" className="footer__phone-item">8 343 20-20-696</a>
                </div>

                <div className="footer__socials">
                    <a href="https://instagram.com/magnit96" target="_blank"><img src="img/inst.png" alt="" /></a>
                    <a href="https://facebook.com/magnit96" target="_blank"><img src="img/fb.png" alt="" /></a>
                    <a href="https://vk.com/magnit96com" target="_blank"><img src="img/vk.png" alt="" /></a>
                </div>
            </div>

            <div className="footer__copy">
                © Magnit96 - Крупнейший розничный магазин магнитов.
            </div>
        </div>
    )
}

export default observer(HomeFooter);