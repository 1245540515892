import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import cart_store, { CartItemInterface, CityInterface, DeliveryData, DeliveryPoint, DeliveryTypeItem } from "../../store/cart_store";
import CartItem from "./CartItem";

const Cart:FC<any> = () => {
    const [cartData, setCartData] = useState<CartItemInterface[]>([]);
    const [itemLoader, setItemLoader] = useState(false);
    const [priceSum, setPriceSum] = useState(0);

    const [fieldError, setFieldError] = useState({city: '', name: '', deliveryType: '', deliveryPoint: ''})
    const [userName, setUsrName] = useState('');
    const [userCity, setUserCity] = useState('-1');

    const [cityInput, setCityInput] = useState('');
    const [cityInputList, setCityInputList] = useState<CityInterface[]>();
    const [cityId, setCityId] = useState(-1);
    const [showCityDrop, setShowCityDrop] = useState(false);

    const [deliveryType, setDeliveryType] = useState<DeliveryTypeItem[]>();
    const [deliveryTypeId, setDeliveryTypeId] = useState("-1");

    const [deliveryPoint, setDeliveryPoint] = useState<DeliveryPoint[]>();
    const [deliveryPointId, setDeliveryPointId] = useState("-1");
    const [deliveryData, setDeliveryData] = useState<DeliveryData>();
    const [deliveryPrice, setDeliveryPrice] = useState(0);

    const [orderSum, setOrderSum] = useState();


    const [cartLoader, setCartLoader] = useState(false);

    const getCartData = async () => {
        const res = (await cart_store.getCart()).cart;
        setCartData(res);
        // console.log(res);

        setCartLoader(false);
    }

    const removeItem = async (id: any, itemCount: number) => {
        if (itemCount > 1) {
            await cart_store.cartUpdate(id, 1);;
        }
        const res = await cart_store.cartRemove(id);
        if (res == true) {
            setCartLoader(true);
            getCartData();
        }
    }

    const validateFields = () => {
        let errors = false;

        if (cityId == -1) {
            setFieldError(prevState => ({
                ...prevState,
                city: 'error',
            }))

            errors = true;
        } else {
            setFieldError(prevState => ({
                ...prevState,
                city: '',
            }))
        }

        if (userName.length < 2) {
            setFieldError(prevState => ({
                ...prevState,
                name: 'error',
            }))

            errors = true;
        } else {
            setFieldError(prevState => ({
                ...prevState,
                name: '',
            }))
        }


        if (deliveryTypeId == "-1") {
            setFieldError(prevState => ({
                ...prevState,
                deliveryType: 'error',
            }))

            errors = true;
        } else {
            setFieldError(prevState => ({
                ...prevState,
                deliveryType: '',
            }))
        }

        if (deliveryPointId == "-1") {
            setFieldError(prevState => ({
                ...prevState,
                deliveryPoint: 'error',
            }))

            errors = true;
        } else {
            setFieldError(prevState => ({
                ...prevState,
                deliveryPoint: '',
            }))
        }

        return errors;
    }

    const sendOrder = async () => {
        if (validateFields()) {return false;}
        setCartLoader(true);

        const res = await cart_store.sendOrder(cityId, userName, parseInt(deliveryTypeId), parseInt(deliveryPointId));
        if (res.payment_url) {
            window.location.href = res.payment_url;
        }
    }

    const itemEdit = (item:CartItemInterface, index: number) => {
        let newData = cartData;
        newData[index] = item;
        setCartData(newData);

        let price:any = 0;
        cartData?.map((obj) => {
            price += obj.count*obj.price;
        } )

        price = price.toFixed(2)

        if (deliveryPrice > 0) {
            let dp = deliveryPrice.toFixed(2),
                sum = parseFloat(price)+parseFloat(dp);
            setPriceSum(parseFloat(sum.toFixed(2)));
        } else {
            setPriceSum(price);
        }

        // setOrderSum(price);
    }

    const getCity = async (search_str: string) => {
        const cities = await cart_store.searchCity(search_str);
        setCityInputList(cities);
        setShowCityDrop(true);
    }

    const onCitySelect = (item: CityInterface) => {
        setCityInput(item.city); 
        setCityId(item.id);
        setShowCityDrop(false);

        setDeliveryType(undefined);
        setDeliveryTypeId('-1');

        setDeliveryPoint(undefined);
        setDeliveryPointId('-1');

        getDeliveryMethod(item.id)
    }

    const getDeliveryMethod = async (city_id: number) => {
        const methods = await cart_store.getDeliveryMethods(city_id);
        setDeliveryType(methods);
    }
 
    const getDeliveryPrice = async (city_id: number) => {
        const deliveryInfo = await cart_store.getDeliveryPrice(city_id);
        setDeliveryData(deliveryInfo);
        setDeliveryPrice(deliveryInfo.delivery_price.delivery_price);

        // setPriceSum(priceSum+deliveryInfo.delivery_price.delivery_price);
    }

    const getDelPoints = async () => {
        const deliveryPointList = await cart_store.getDeliveryPoints(cityId, parseInt(deliveryTypeId));
        if (parseInt(deliveryTypeId) != 2) {
            getDeliveryPrice(cityId);
        } else {
            setDeliveryData(undefined);
            setDeliveryPrice(0);
        }

        setDeliveryPoint(deliveryPointList);
    }

    useEffect(() => {
        // setCityId(-1);
        if (cityInput.length > 3) {
            const searchTimeout = setTimeout(() => {getCity(cityInput)}, 500);
            
            return () => clearTimeout(searchTimeout)
        }
    }, [cityInput])

    useEffect(() => {
        if (deliveryTypeId != "-1") {
            getDelPoints();
        }
    }, [deliveryTypeId])

    useEffect(() => {
        if (cartData.length == 0) {
            getCartData();
        }
    }, [])

    const CartContent:FC = () => {

        return (
            <>
            { cartData?.length>0?cartData?.map((item: CartItemInterface) => {
                return (
                    <CartItem
                        key={item.id} 
                        data={item}
                        onEdit={(itemData:CartItemInterface) => {itemEdit(itemData, cartData.findIndex((obj => obj.id == item.id)))}}
                        onRemove={(id: any, itemCount: number) => {removeItem(id, itemCount)}} 
                        />
                )
            }):<></> }
            </>
        )
    }

    const CartLoader = () => {
        if (!cartLoader) { return (<></>) }
        return (
            <div className="cart__loader">
                <div className="lds-dual-ring"></div>
            </div>
        )
    }

    return (
        <div className="page-content cart">
            <CartLoader />
            <div className="cart-page__title">Оформление заказа</div>
            <div className={`cart-page__items ${itemLoader?'anim-loader':''}`}>
                <CartContent />
            </div>

            <div className="cart-page__user">
                <div className="user__form">
                    <div className="cart-page__form-title">Данные покупателя</div>
                    <input type="text" className={`cart-page__form-input ${fieldError.name}`} placeholder="ФИО" value={userName} onChange={(e) => {setUsrName(e.target.value)}} />
                </div>
                <div className="user__form">
                    <div className="cart-page__form-title">Способ получения</div>

                    <div className="cart-page__form-container">
                        <input type="text" className={`cart-page__form-input ${fieldError.city}`} placeholder="Выберите город" value={cityInput} onChange={(e) => {setCityInput(e.target.value);}} />
                        <div className="cart-page__form-dropdown">
                            {cityInputList?cityInputList.map((item: CityInterface) => {
                                if (showCityDrop == false) {
                                    return false;
                                }

                                return (
                                    <div className="cart-page__form-dropdown-item" key={item.id} onClick={() => {onCitySelect(item)}}>{item.city}</div>
                                )
                            }):<></>}
                        </div>
                    </div>
                    <select className={`cart-page__form-input ${fieldError.deliveryType}`} onChange={(e) => {setDeliveryTypeId(e.target.value);}}>
                        <option value="-1" >-- Способ получения --</option>
                        {deliveryType?.map((item) => {
                            return (
                                <option key={item.id} value={item.id}>{item.title}</option>
                            )
                        })}
                    </select>

                    <select className={`cart-page__form-input ${fieldError.deliveryPoint}`} onChange={(e) => {
                        setDeliveryPointId(e.target.value);
                    }}> 
                        <option value="-1">-- Пункт выдачи --</option>
                        {(deliveryPoint != undefined && deliveryPoint)?deliveryPoint?.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.delivery_point}</option>
                            )
                        }):<></>}
                    </select>

                    { deliveryData &&
                        <div className="cart-page__form-delivery">
                            <div>Дней для доставки: {deliveryData.delivery_price.delivery_days}</div>
                            <div>Цена доставки: {deliveryData.delivery_price.delivery_price} ₽</div>
                        </div>
                    }
                </div>
                <div className="user__form">
                    <input type="checkbox" checked readOnly />
                    <div className="cart-page__form-checkbox">
                        НАЖИМАЯ КНОПКУ "ОТПРАВИТЬ ЗАКАЗ", Я ПРИНИМАЮ УСЛОВИЯ <a target="_blank" href="https://magnit96.com/static/user_agreement.pdf">ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</a> И ДАЮ СВОЕ СОГЛАСИЕ НА ОБРАБОТКУ МОИХ ПЕРСОНАЛЬНЫХ ДАННЫХ, В СООТВЕТСТВИИ С ФЕДЕРАЛЬНЫМ ЗАКОНОМ ОТ 27.07.2006 ГОДА №152-ФЗ "О ПЕРСОНАЛЬНЫХ ДАННЫХ"
                    </div>

                    <button onClick={sendOrder} className={`cart-page__confirm ${(!cartData || cartData?.length == 0)?'disabled': ''}`}>Отправить заказ</button>

                    <div className="user__form-summary">
                        <div>Итого:</div>
                        {/* <div className="user__form-summary-sum">{priceSum+deliveryPrice} ₽</div> */}
                        <div className="user__form-summary-sum">{priceSum} ₽</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Cart);