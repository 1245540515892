import config from "../config";
import $api from "../http";
import UserService from "../services/UserService";

declare global {
	interface Window {
		Telegram?: any;
	}
}

class TgStore {
    tele: any;
    user: any;
    user_id: number;
    status: any;
    tokens: any;
    loader: boolean; 
    initData: any;

    constructor() {
        this.loader = true;
        // this.user_id = config.DEV?170719132:0;
        this.user_id = 170719132;

        this.tele = window.Telegram.WebApp;
        if (this.tele.initData.length > 0) {
            this.initData = this.tele.initDataUnsafe
            this.user = this.initData.user
            this.user_id = this.user.id
        }

        this.status = this.tele.initData.length > 0?true:false;
    }

    closeWebApp() {
        this.tele.close();
    }

    setLoader(state: boolean) {
        this.loader = state;
    }

    createCheckScring() {
        let data = this.tele.initData;
        data = data.split('&').sort().join('\n');
    
        return data;
    }
}

export default new TgStore();