import ContentService from "../services/ContentService";

class ContentStore {
    curTitle: string;
    curId: number;

    constructor() {
        this.curTitle = '';
        this.curId = 0;
    }

    setCurTitle(title: string) {
        this.curTitle = title;
    }

    setCurId(id: number) {
        this.curId = id;
    }

    async getCategories() {
        try {
            const res = ContentService.getCategories();
            return res;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getCategoryItem(id: any) {
        try {
            const res = ContentService.getCategoryItem(id);
            return res;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getProduct(id: any) {
        try {
            const res = ContentService.getProduct(id);
            return res;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}

export default new ContentStore