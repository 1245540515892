import { NavLink } from "react-router-dom"

// export const CartSuccessPopup = ({show}: any, {close}: any) => {
export const CartSuccessPopup = (props: any) => {
    if (!props.show) { return ( <></> ) }

    return (
        <div className="cart__success">
            <div className="cart__success-content">
                <button className="cart__success-close" onClick={() => {props.close()}}>╳</button>
                <div className="cart__success-title">Товар добавлен в корзину!</div>
                <NavLink to="/cart" className="cart__success-nav">Оформить заказ</NavLink>
                <button className="cart__success-continue" onClick={() => {props.close()}}>Продолжить покупки</button>
            </div>
        </div>
    )
}
