import { observer } from "mobx-react-lite";
import { FC } from "react";

const SuccessOrder:FC<any> = () => { 
    return (
        <div className="page-content success">
            <h2>Ваш заказ успешно создан!</h2>
            <h3>Сообщения о статусах заказа вы будете получать в чат с ботом</h3>
        </div>
    )
}

export default observer(SuccessOrder);