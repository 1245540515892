import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react";
import HomeItem from "./HomeItem";
import config from "../../config";
import tg_store from "../../store/tg_store";
import Loader from "../Loader";
import UserService from "../../services/UserService";
import contentStore from "../../store/contentStore";

interface CategoryItem {
    id: number,
    image: string,
    title: string,
}

const Home:FC<any> = (props: any) => {
    const [loader, setLoader] = useState(false); // true - если надо чтобы до отображения категории был прелоадер
    const [data, setData] = useState<CategoryItem[]>([]);

    useEffect(() => {
        // getData();
    }, [])

    useEffect(() => {
        if (props.data?.length > 0) {
            setLoader(false);
            setData(props.data);
        }
    }, [props.data])

    const getData = async () => {
        const res = await contentStore.getCategories();
        setData(res);
        // console.log(res);

        setLoader(false);
    }



    if (loader || !props.data || props.data?.length == 0) {
        return (
            <div className="content">
                <Loader />
            </div>
        )
    }

    return (
        <div className="content">
            <div className="content__items">
                { data.length>0?data.map((item) => {
                    return (
                        <HomeItem img={item.image} title={item.title} id={item.id} key={item.id} />
                    )
                }):<></>}
            </div>
        </div>
    )
}

export default observer(Home);