import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react"
import cart_store, { OrdersInterface } from "../../store/cart_store";

const Orders:FC<any> = () => {
    const [ordersData, setOrdersData] = useState<OrdersInterface[]>([]);
    const [showOrderPopup, setShowOrderPopup] = useState(false);
    const [orderPopupData, setOrderPopupData] = useState<OrdersInterface>();
    
    const getOrders = async () => {
        const res = await cart_store.getOrders();
        setOrdersData(res.orders);
    }

    useEffect(() => {
        getOrders();
    }, [])

    const OrderPopup = (props:any) => {
        if (!props.show) {
            return <></>;
        }

        let sum = 0;
        return (
            <div className="order-popup">
                <div className="order-popup__content">
                    <div className="order-popup-close" onClick={() => {setShowOrderPopup(false)}}>╳</div>

                    <div className="order-popup__title">
                        <div>Заказ № {orderPopupData?.id}</div>
                        <div>{orderPopupData?.status}</div>
                    </div>

                    {orderPopupData?.items.map((item) => {
                        sum += item.count*item.price;
                        return (
                            <div className="order-popup__item">
                                <div className="order-popup__item-title">{item.title}</div>
                                <div className="order-popup__item-prop">Количество: {item.count}</div>
                                <div className="order-popup__item-prop">Цена: {item.price} ₽</div>
                            </div>
                        )
                    })}
                    <div className="order-popup__info">
                        <h3>Информация о заказе:</h3>
                        <p>Способ доставки: {orderPopupData?.delivery_method}</p>
                        <p>Город: {orderPopupData?.city}</p>
                        <p>Адрес: {orderPopupData?.address}</p>
                        <p>ФИО: {orderPopupData?.name}</p>
                    </div>

                    <div className="order-popup__sum">ИТОГ: {sum} ₽</div>
                </div>
            </div>
        )
    }

    return (
        <div className="page-content order">
            <OrderPopup show={showOrderPopup} />
            { ordersData && ordersData.length > 0 && 
                ordersData.map((order) => {
                    return (
                        <div className="order__item" key={order.id} onClick={() => {setOrderPopupData(order); setShowOrderPopup(true);}}>
                            <div className="order__id">Заказ №{order.id}</div>
                            <div className="order__status">{order.status}</div>
                        </div>
                    )
                }) }
        </div>
    )
}

export default observer(Orders);