import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cart_store, { CategoryItem } from "../store/cart_store";
import contentStore from "../store/contentStore";

const Header:FC<any> = (props: any) => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [count, setCount] = useState(0);
    const [categories, setCategories] = useState<CategoryItem[]>();

    const countUpdater = () => {
        setCount(cart_store.cart_count);
        setTimeout(() => {
            countUpdater();
        }, 1000);
    }

    const saveCategoryData = (id: number, title: string) => {
        contentStore.setCurId(id);
        contentStore.setCurTitle(title);
        setShowMenu(false);
    }

    useEffect(() => {
        if (props.categories?.length > 0) {
            setCategories(props.categories);
        }
    }, [props.categories])

    useEffect(() => {
        setCount(cart_store.cart_count);
        countUpdater();
    }, [])

    useEffect(() => {
        setCount(cart_store.cart_count);
    }, [cart_store.cart_count])

    return (
        <div className="header">
            <div className={`header__container ${showMenu?"active":""}`}>
                <div className="header__menu-btn" onClick={() => {setShowMenu(!showMenu)}}><span></span></div>
                <div className="header__logo"><NavLink to="/" onClick={() => {setShowMenu(false)}}><img src="/img/mobile-logo.png" alt="" /></NavLink></div>
                {/* <div className="header__cart-btn"> */}
                <NavLink to="/cart" className="header__cart-btn" onClick={() => {setShowMenu(false)}}>
                    <img src="/img/icon-cart.png" alt="" />
                    <div className="header__cart-text">{count} шт</div>
                </NavLink>
                {/* </div> */}
            </div>
            <div className={`header__menu ${showMenu?"active":""}`}>
                { showMenu && 
                    <div className="header__menu-content">
                        <div className="header-menu__top">
                            {/* <div className="header-menu__item">Личный кабинет</div> */}
                            <NavLink to='/orders' className="header-menu__item" onClick={() => {setShowMenu(false)}}>Заказы</NavLink>
                            <div className="header-menu__list">
                                {categories?.map((item:CategoryItem) => {
                                    return (
                                        <NavLink 
                                            onClick={() => { saveCategoryData(item.id, item.title) }}
                                            key={item.id} 
                                            to={`/category/${item.id}`} 
                                            className='header-menu__item'>{item.title}</NavLink>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="header-menu__bottom">
                            <div className="header-menu__phone">
                                <a href="tel:88005554296" className="header-menu__phone-item">8 800 555-42-96</a>
                                <div className="header-menu__phone-sub">Горячая линия</div>
                                
                                <a href="tel:834320-20-696" className="header-menu__phone-item">8 343 20-20-696</a>
                            </div>

                            <ul className="header-menu__bottom-links">
                                <li><a target="_blank" href="https://magnit96.com/info/dostavka_po_rossii/">Доставка по России</a></li>
                                <li><a target="_blank" href="https://magnit96.com/info/payment_methods/">Способы оплаты</a></li>
                                <li><a target="_blank" href="https://magnit96.com/info/politika_vozvrata/">Политика возврата</a></li>
                            </ul>
                            <div className="header-menu__copy">
                                <div className="header-menu__socials">
                                    <a href="https://instagram.com/magnit96"><img src="/img/inst.png" alt="" /></a>
                                    <a href="https://facebook.com/magnit96"><img src="/img/fb.png" alt="" /></a>
                                    <a href="https://vk.com/magnit96com"><img src="/img/vk.png" alt="" /></a>
                                </div>
                                <div className="header-menu__copy-text">
                                    © Magnit96 - Крупнейший розничный магазин магнитов.
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default observer(Header);