import axios from "axios";
import config from "../config";
import UserService from "../services/UserService";
import tg_store from "../store/tg_store";
// import { AuthResponce } from "../models/responce/AuthResponce";
export const API_URL = config.DEV?config.API_URL_DEV:config.API_URL;



const $api = axios.create({
    // withCredentials: true,
    baseURL: API_URL,
})

$api.interceptors.request.use((config) => {
    config.headers!.Authorization = `Bearer ${localStorage.getItem('access_token')}`
    return config;
})

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status == 401 && error.config && !originalRequest._isRetry) {
        originalRequest._isRetry = true;

        try {
            // const responce = await axios.get(`${API_URL}/refresh`, {withCredentials: true})
            const responce = await axios.post(`${API_URL}/refresh`, null, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('refresh_token')}`
                }
            })
            localStorage.setItem('access_token', responce.data.access_token);
            localStorage.setItem('refresh_token', responce.data.refresh_token);

            UserService.setTokens(tg_store.user_id, {access_token: responce.data.access_token, refresh_token: responce.data.refresh_token });
    
            return $api.request(originalRequest)
        } catch (e) {
            console.log('не авторизован')
        }
    }

    throw error;
})

export default $api;