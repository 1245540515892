import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import cart_store, { CartItemInterface, CategoryItem } from "../../store/cart_store";
import contentStore from "../../store/contentStore";

const CartItem:FC<any> = (props: any) => {
    const [data, setData] = useState<CartItemInterface>(props.data);
    const [itemCount, setItemCount] = useState(1);
    const [changeTimeout, setChangeTimeout] = useState<any>(null);

    // let countChangeTimeout:any = null;
 
    const cartPlus = async () => {
        if (itemCount+1 > data.rest) {return false; }

        setItemCount(itemCount+1);
        updateCountData(itemCount+1);

        // setData(prevState => {
        //     return {
        //         ...prevState,
        //         count: prevState.count+1,
        //     }
        // })
        // setTimeout(() => {
        //     console.log(data);
        // }, 50);
        // sendUpdate("add");

        // await cart_store.cartAdd(data?.id).then(() => {
        //     // props.onEdit(data);
        // });

    }

    const cartMinus = async () => {
        if (itemCount < 2) { return false; }
        
        setItemCount(itemCount-1);
        updateCountData(itemCount-1);

        // setData(prevState => {
        //     return {
        //         ...prevState,
        //         count: prevState.count-1,
        //     }
        // })
        // await cart_store.cartUpdate(data?.id, data.count-1);
        // setPrice(itemCount-1);
    }

    const setPrice = (count:number, price:any = false) => {
        // if (price) {
        //     const summPrice = (count*parseFloat(price)).toFixed(2);
        //     props.onPriceSet(parseFloat(summPrice));

        //     return true;
        // }

        // const summPrice = data?.price?(count*parseFloat(data?.price)).toFixed(2):0;
        // if (summPrice) {
        //     props.onPriceSet(parseFloat(summPrice));
        // }
    }

    const sendUpdate = async (state:string) => {
        // await cart_store.cartAdd(data?.id).then(() => {
            
        // });
        
        // console.log(data);
    }

    const updateItemCount = async (e:any) => {
        if (e.target.value.length == 0 || e.target.value == '0') { e.target.value = 1; }
        let value = e.target.value.length==0?0:parseInt(e.target.value);

        if (value > data.rest) {
            value = data.rest;
        }

        setItemCount(value);
        updateCountData(value);
        
    }

    const updateCountData = async (value: number) => {
        // if (countChangeTimeout =!)
        if (changeTimeout != null) {
            clearTimeout(changeTimeout); 
        }

        setChangeTimeout(setTimeout(async () => {
            setData(prevstate => {
                return {
                    ...prevstate,
                    count: value,
                }
            })

            await cart_store.cartUpdate(data?.id, value).then();
        }, 1000))
    }

    useEffect(() => {
        if (props.data?.id) {
            setPrice(itemCount, data?.price);
            setItemCount(props.data.count);

            // sendUpdate();
            props.onEdit(data);
        }

        // console.log(data);
    }, [data])


    useEffect(() => {
        // getData();
    }, [])

    return (
        <div className="cart-item">
            <button className="cart-item__remove" onClick={() => {props.onRemove(data?.id, data.count)}} >╳</button>
            <div className="cart-item__top">
                <div className="cart-item__image" style={{
                    backgroundImage: `url(${data?.image})`
                }}></div>
                <div className="cart-item__title">{data?.title}</div>
            </div>
            <div className="cart-item__bottom">
                <div className="cart-item__bottom--counter">
                    <div className="cart-item__counter">
                        <input className="cart-item__counter-num" type="number" min="1" value={itemCount} onChange={(e) => {updateItemCount(e)}} />
                        <div className="cart-item__counter-arrows">
                            <button onClick={cartPlus} className="cart-item__arrows-plus"></button>
                            <button onClick={cartMinus} className="cart-item__arrows-minus"></button>
                        </div>
                    </div>
                    <div className="cart-item__price">{data?.price} руб./шт.</div>
                </div>
                <div className="cart-item__summ">{data?.price?(itemCount*data?.price).toFixed(2):0} ₽</div>
            </div>
        </div>
    )
}

export default observer(CartItem);