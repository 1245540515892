import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react";

const Loader:FC<any> = () => {
    return (
        <div className="App">
            <div className="App__loader">
                <div className="lds-dual-ring"></div>
            </div>
        </div>
    )
}

export default observer(Loader);