import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import cart_store from "../../store/cart_store";
import CategoryItemInterface from "../../store/category_store";

const CategoryItem:FC<any> = (props: any) => {
    const { id } = useParams();

    useEffect(() => {
        // console.log(props.data.params);
    }, [])

    const addToCart = async (id: number) => {
        console.log(id);
    }

    return (
        <div className="category__item">

            <NavLink to={`/product/${props.data.id}`} className="category__item--link"></NavLink>
            <img className="item__image" src={props.data.image} />
            <div className="item__content">
                <div className="item__title">{props.data.title}</div>
                <div className="item__props">
                    {props.data.params["Класс"] &&
                        <div className="item__prop">
                            <div className="item__prop--image"></div>
                            <div className="item__prop--text">
                                <i className="item__icon"><div className="item__icon--text">N</div></i>
                                <div className="item__prop-text">{props.data.params["Класс"]}</div>
                            </div>
                        </div>
                    }
                    {props.data.params["Вес"] &&
                        <div className="item__prop">
                            <div className="item__prop--image"></div>
                            <div className="item__prop--text">
                                <i className="item__icon"><img src="data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='12.4px' height='17.6px' viewBox='0 0 12.4 17.6' style='enable-background:new 0 0 12.4 17.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23C8D3E1;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cg%3E%3Cpath id='bpstrange.svg_1_' class='st0' d='M0,17.6L2.4,7.2l4.9,0.5L12.4,0l-1.9,11.9l-4.9-0.5L0,17.6z'/%3E%3C/g%3E%3C/svg%3E%0A" alt="" /></i>
                                <div className="item__prop-text">{props.data.params["Вес"]} гр.</div>
                            </div>
                        </div>
                    }
                    {props.data.params["Покрытие"] &&
                        <div className="item__prop">
                            <div className="item__prop--image"></div>
                            <div className="item__prop--text">
                                <i className="item__icon"><img src="data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='17.9px' height='11.6px' viewBox='0 0 17.9 11.6' style='enable-background:new 0 0 17.9 11.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23C8D3E1;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cg%3E%3Cpath id='aqcoverage.svg_1_' class='st0' d='M0,11.6L2.3,0h13.2l2.3,11.6H0z M14.9,0.9h-12l-1.9,9.8h15.7L14.9,0.9z M4.8,2.7h8.1 l1.4,6.3H3.6L4.8,2.7z'/%3E%3C/g%3E%3C/svg%3E%0A" alt="" /></i>
                                <div className="item__prop-text">{props.data.params["Покрытие"]}</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="item__bottom">
                    <div className="item__price">
                        {props.data.old_price == '' &&
                            props.data.price + ' ₽'    
                        }

                        {props.data.old_price != '' &&
                            <>
                            <span className="item__price--new">{props.data.price} ₽</span>
                            <span className="item__price--old">{props.data.old_price} ₽</span>
                            </>
                        }
                        
                        
                    </div>
                    <button className="item__button" onClick={() => {cart_store.cartAdd(props.data.id); props.onCartAdd()}}></button>
                </div>
        {/* </NavLink> */}
        </div>
    )
}

export default observer(CategoryItem);