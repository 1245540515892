import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import './App.css';
import './App.scss';
import tg_store from './store/tg_store';
import { observer } from 'mobx-react-lite';
import Header from './components/Header';
import Home from './components/home/Home';
import HomeFooter from './components/home/HomeFooter';
import Category from './components/category/Category';
import Product from './components/product/Product';
import UserService from './services/UserService';
import Loader from './components/Loader';
import cart_store, { CategoryItem } from './store/cart_store';
import Cart from './components/cart/Cart';
import Orders from './components/cart/Orders';
import SuccessOrder from './components/cart/SuccessOrder';
import contentStore from './store/contentStore';

function App() {
  const [hasTokens, setHasTokens] = useState(true);
  const [loader, setLoader] = useState(true);
  const [categories, setCategories] = useState<CategoryItem[]>();
  const location = useLocation();


  useEffect(() => {
    tg_store.tele.ready()

    getTokens();
  }, []);

  const getTokens = async () => {
    const result = await UserService.getTokens(tg_store.user_id);
    if (result == '' || ( result.access_token == '' || result.refresh_token == '' )) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      setHasTokens(false);
      setLoader(false);
      return false;
    }
    localStorage.setItem('access_token', result.access_token);
    localStorage.setItem('refresh_token', result.refresh_token);

    setHasTokens(true);
    setLoader(false);

    getCategories();
  }

  const getCategories = async () => {
    const res = await contentStore.getCategories();
    setCategories(res);
    
    // setLoader(false);
}

  if (location.pathname == '/success') {
    return (
      <div className="App fullscreen">
        <Routes>
          <Route path="/success" element={<SuccessOrder />}></Route>
        </Routes>
  
        <HomeFooter />
      </div>
    );
  }

  if (!hasTokens) {
    return (
      <div className="App">
        <div className="no-token__container">
          <div className="no-token__content">
            Вы не авторизованы, либо слишком давно не заходили в бота, пожалуйста, передайте номер телефона
          </div>
          <button className="no-token__button" onClick={()=> {tg_store.closeWebApp()}}>Вернуться в бота</button>
        </div>
      </div>
    )
  }

  if (loader) {
    return (
      <Loader />
    )
  }


  return (
    <div className="App">
      <Header categories={categories} />
      <Routes>
				<Route path="/" element={<Home data={categories} />}/>
				<Route path="/category/:id/" element={<Category />}/>
        <Route path="/product/:id/" element={<Product />}/>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/orders" element={<Orders />}></Route>
        <Route path="/success" element={<SuccessOrder />}></Route>
			</Routes>

      <HomeFooter />
    </div>
  );
}

export default observer(App);
