import config from "../config";
import $api from "../http";

const SERVER_API_URL = config.DEV?config.SERVER_API_URL_DEV:config.SERVER_API_URL

class UserService {
    async getTokens(tg_id: any) {
        const result = await $api.get(`${SERVER_API_URL}/tokens/${tg_id}`)
        return result.data;
    }

    async setTokens(tg_id: any, data: any) {
        await $api.put(`${SERVER_API_URL}/tokens/${tg_id}`, data);
    }
}

export default new UserService;