import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import CategoryItemInterface from "../../store/category_store";
import contentStore from "../../store/contentStore";
import Loader from "../Loader";
import { CartSuccessPopup } from "../Popup";
import CategoryItem from "./CategoryItem";

interface CategoryItem {
    discount: any,
    id: number,
    image: string,
    params: any,
    price: string,
    title: string
}

const Category:FC<any> = () => {
    const { id } = useParams();
    const [loader, setLoader] = useState(true);
    const [title, setTitle] = useState('Магнитные диски');
    const [data, setData] = useState<CategoryItem[]>([]);
    const [cartSuccessVisible, setCartSuccessVisible] = useState<boolean>(false);

    useEffect(() => {
        setLoader(true);
        getData();
        setTitle(contentStore.curTitle);
    }, [id])

    const getData = async () => {
        const res = await contentStore.getCategoryItem(id);
        setData(res.items);
        setLoader(false);
    }

    


    if (loader) {
        return (
            <div className="content">
                <Loader />
            </div>
        )
    }

    return (
        <div className="page-content">
            <CartSuccessPopup show={cartSuccessVisible} close={() => {setCartSuccessVisible(false);}}/>

            <div className="page__breadcrubms">
                <NavLink to="/">Магниты</NavLink> / <span>{title}</span>
            </div>

            <div className="page__title">{title}</div>

            {/* Category: {id} */}
            <div className="category">
                {data.length>0?data.map((item) => {
                    return (
                        <CategoryItem data={item} onCartAdd={() => {setCartSuccessVisible(true)}} key={item.id}/>
                    )
                }):<></>}
            </div>
        </div>
    )
}

export default observer(Category);