import { observer } from "mobx-react-lite"
import { ChangeEvent, FC, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import CategoryItemInterface from "../../store/category_store";
import contentStore from "../../store/contentStore";
import cart_store, { CategoryItem } from "../../store/cart_store";
import Loader from "../Loader";
import { CartSuccessPopup } from "../Popup";

const Product:FC<any> = () => {
    const { id } = useParams();
    const [data, setData] = useState<CategoryItem>();
    const [loader, setLoader] = useState(true);
    const [cartNum, setCartNum] = useState(1);
    const [params, setParams] = useState([]);
    const [tab, setTab] = useState('params');
    const [cartSuccessVisible, setCartSuccessVisible] = useState<boolean>(false);

    useEffect(() => {
        getData();
    }, [])

    const updateItemCount = async (e: any) => {
        let value:any = e.target.value;
        
        value = value?value:"1";
        value = value.length == 0?1:value;
        value = isNaN(parseInt(value))?1:parseInt(value);

        if (data?.rest && value > data?.rest) {
            value = data?.rest;
        }

        setCartNum(value);
    }

    const getData = async () => {
        const res = await contentStore.getProduct(id);
        setData(res);
        setLoader(false);

        // console.log(res);
    }

    const cartAdd = async () => {
        // cart_store.cartAdd(data?.id); 
        cart_store.cartUpdate(data?.id, cartNum);
        setCartSuccessVisible(true)
    }

    if (loader) {
        return (
            <div className="content">
                <Loader />
            </div>
        )
    }

    return (
        <div className="page-content product">
            <CartSuccessPopup show={cartSuccessVisible} close={() => {setCartSuccessVisible(false);}}/>

            <div className="product__breadcrumbs">
                <NavLink to='/'>Главная</NavLink>
                <NavLink to={`/category/${contentStore.curId}`}>{contentStore.curTitle}</NavLink>
                <span>{data?.title}</span>
            </div>

            <div className="product__image"><img src={data?.image} alt="" /></div>

            <div className="product__top">
                <div>
                    <div className="product__top--price">
                        {/* {data?.price} ₽ */}
                        {data?.old_price == '' &&
                            data?.price + ' ₽'    
                        }

                        {data?.old_price != '' &&
                            <>
                            <span className="item__price--new">{data?.price} ₽</span>
                            <span className="item__price--old">{data?.old_price} ₽</span>
                            </>
                        }
                    </div>
                    <div className="product__top--title">{data?.title}</div>
                </div>

                <div className="product__counter">
                    <div className="product__counter--char" onClick={() => {
                        return cartNum-1>0?setCartNum(cartNum-1):false;
                    }}>-</div>
                    <input className="product__counter--num" value={cartNum} onChange={(e) => updateItemCount(e)} />
                    <div className="product__counter--char" onClick={() => {setCartNum(cartNum+1)}}>+</div>
                </div>

            </div>
            
            <div className="product__content">
                <button className="product__cart-add" onClick={() => {cartAdd()}}>Добавить в корзину</button>

                <div className="product__tabs">
                    <div className="product__tab-links">
                        <button className={`product__tab-link ${tab=='params'?'active':''}`} onClick={() => {setTab('params')}}>Характеристики</button>
                        <button className={`product__tab-link  ${tab=='description'?'active':''}`} onClick={() => {setTab('description')}}>Описание</button>
                    </div>

                    <div className="product__tab-content">
                        {tab == 'params' && 
                        <div className="product__tab product__tab--params">
                            <ul>
                                { Object.keys(data?.params).map((key) => {
                                    return (
                                        <li key={key}>
                                            <div>{key}</div>
                                            <div className="product__tab-divider"></div>
                                            <div>{data?.params[key]}</div>
                                        </li> 
                                    )
                                })}
                            </ul>
                        </div>
                        }

                        {tab == 'description' && 
                        <div className="product__tab product__tab--description" dangerouslySetInnerHTML={{__html:data?.description}}>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Product);