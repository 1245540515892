import React from "react";
import CategoryItem from "../components/category/CategoryItem";
import $api from "../http";
import tg_store from "./tg_store";

interface CartData {

}

export interface CategoryItem {
    description: any,
    discount: any,
    id: number,
    image: string,
    params: any,
    price: string,
    title: string,
    rest: number,
    old_price: string,
}

export interface CartItemInterface {
    id: number,
    count: number,
    price: number,
    image: string,
    title: string,
    rest: number,
}

export interface OrderItems {
    count: number, 
    discount: string, 
    image: string, 
    item_id: number, 
    price: number, 
    title: string,
}

export interface OrdersInterface {
    id: number, 
    status: string,
    city: string,
    address: string,
    delivery_method: string,
    name: string,
    items: [OrderItems],
}

export interface CityInterface {
    id: number,
    city: string,
}

export interface DeliveryTypeItem {
    id: number,
    title: string,
}

export interface DeliveryPoint {
    id: number,
    delivery_point: string,
}

export interface DeliveryDataItem {
    delivery_days: number,
    delivery_price: number,
    delivery_price_custom: string
}

export interface DeliveryData {
    delivery_price: DeliveryDataItem,
}


class CartStore extends React.Component {
    cart: any;
    cart_count: number;

    constructor(props: any) {
        super(props)

        this.getCart();
        this.cart_count = 0;
    }

    countCart(data:CartItemInterface[]) {
        let counter = 0;
        data.map((item) => {
            counter += item.count;
        })

        return counter;
    }

    async getCart() {
        const res = await $api.get('/cart');
        this.cart_count = 0;
        
        if (res.data.cart && res.data.cart.length > 0) {
            this.cart = res.data;
            this.cart_count = this.countCart(res.data.cart);
            return res.data;
        }
        return [];
    }

    async cartAdd(id: any) {
        const res = await $api.post(`/cart/add/${id}`)
        if (res.data == true) {
            // await this.getCart();
            this.cart_count++;
            // Успешное добавление
        }
    }

    async cartUpdate(id: any, count: any) {
        const data = JSON.parse(`{ "${id}": ${count} }`);

        const res = await $api.post(`/cart/update`, data)
        if (res.data == true) {
            await this.getCart();
        }

        return res.data;
    }

    async cartRemove(id: any) {
        const res = await $api.post(`/cart/remove/${id}`);
        return res.data
    }

    async sendOrder(city_id:number, name:string, delivery_method_id: number, delivery_point_id: number) {
        const res = await $api.post(`/order`, {city_id, name, delivery_method_id, delivery_point_id})
        return res.data;
    }

    async getOrders() {
        const res = await $api.post('/orders');
        console.log(res.data);
        return res.data;
    }

    async searchCity(search_str: string) {
        const { data } = await $api.post('/search_city', {search_for: search_str});
        return data;
    }

    async getDeliveryMethods(city_id: number) {
        const { data } = await $api.post('/delivery_methods', {city_id})
        return data.methods;
    }

    async getDeliveryPoints(city_id: number, delivery_method_id: number) {
        const { data } = await $api.post('/search_delivery_points', {city_id, delivery_method_id});
        return data;
    }

    async getDeliveryPrice(city_id: number) {
        const { data } = await $api.post('/calc_delivery_price', {city_id});
        return data;
    }
}

// const cart_store = new CartStore({});

export default new CartStore({});